@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$font: 'Noto Sans JP', sans-serif;

$error: #c00000;
$number-product-selection: #3b82f6;

$palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #dcdddd,
  300: #c9caca,
  400: #b5b5b6,
  500: #898989,
  600: #727171,
  700: #595757,
  800: #3e3a39,
  900: #000000,
  A100: #6c708c,
  A200: #343a68,
  A400: #11163c,
  A700: #000210,
  textColor: #515151,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$primary: mat.define-palette($palette, 900);
$accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn
    ),
    typography:
      mat.define-typography-config(
        $font-family: $font,
        $headline-1: mat.define-typography-level(112px, 112px, 900, $letter-spacing: 0.05em),
        $headline-2: mat.define-typography-level(56px, 56px, 800, $letter-spacing: 0.05em),
        $headline-3: mat.define-typography-level(45px, 48px, 600, $letter-spacing: 0.05em),
        $headline-4: mat.define-typography-level(34px, 40px, 600),
        $headline-5: mat.define-typography-level(24px, 32px, 600),
        $subtitle-1: mat.define-typography-level(12px, 18px, 400),
        $body-1: mat.define-typography-level(12px, 18px, 400),
        $body-2: mat.define-typography-level(12px, 18px, 500),
        $button: mat.define-typography-level(12px, 18px, 500)
      ),
    density: 0
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($theme);

// Emit styles for MatButton based on `$theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($theme);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

@include mat.typography-hierarchy($theme);

$cornflowerBlue: #5d62b9;
$primaryColor: #515151;
$darkGray: #515151;
$borderColor: #dcdce2;
$primaryColor2: #343dd6;
$tableHeaderColor: #e2e2e2;

@mixin webkitScroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e7e7e7;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }
}

.scrollable {
  @include webkitScroll;
}

button {
  font-size: 14px;
  font-weight: 700 !important;
  &.btn {
    &:disabled {
      opacity: 0.5;
      background-color: $darkGray;
    }
  }

  &.btn-outline {
    @apply px-5 py-2;
    border: 1px solid $darkGray;
    border-radius: 4px;
    background-color: #fff;
  }
  &.btn-secondary {
    color: #fff;
    @apply px-5 py-2;
    background-color: $cornflowerBlue;
  }

  &.btn-primary {
    color: #fff;
    @apply px-5 py-2;
    background-color: $darkGray;
  }

  &.btn-rounded {
    border: 1px solid $darkGray;
    border-radius: 20px;
  }
}

.table-responsive {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  @include webkitScroll;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      th {
        background-color: $tableHeaderColor;
        padding: 10px;
      }
    }

    tbody {
      tr {
        td:first-child {
          @apply p-2;
        }

        td {
          @apply px-3 py-2;
          border-bottom: 1px solid $tableHeaderColor;
        }
      }
    }
  }

  .head-border {
    th,
    .mat-mdc-header-cell {
      position: relative;

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 8px);
        height: 16px;
        width: 2px;
        background-color: #fff;
      }
    }
  }

  .bg-none {
    background-color: transparent;
  }

  .mat-mdc-header-row {
    height: 36px;
    min-height: 36px;
  }

  .mat-mdc-header-cell {
    font-weight: bold;
    background-color: $tableHeaderColor;
    min-width: 120px;
    font-size: 1rem;
  }

  .mat-mdc-row {
    background-color: transparent;

    &:hover {
      .mat-column-order {
        visibility: visible;
      }
    }
  }

  .mat-mdc-cell {
    background-color: #fff;
    min-width: 120px;
  }

  .mat-column-order {
    min-width: 40px;
    width: 40px;
    padding: 0;
    flex: none;
    border: 0;
    visibility: hidden;
    background-color: transparent;
  }
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-color: #c00000 !important;
}

.disable-validate {
  ::ng-deep {
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
      .mdc-notched-outline
      .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
      .mdc-notched-outline
      .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
      .mdc-notched-outline
      .mdc-notched-outline__trailing,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
      border-color: currentColor;
    }

    .mdc-text-field--invalid .mdc-text-field__input {
      caret-color: currentColor;
    }

    .mat-mdc-form-field-error {
      color: #c00000 !important;
      display: none;
    }
  }

  .text-error {
    color: unset;
  }
}

.text-error {
  color: #c00000;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 32px;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-weight: normal;
}

.table__hover {
  $hoverColor: #b5d8f4;
  $selectedColor: #deeffd;
  $default: #fff;
  $disable: #0000001f;
  tbody {
    tr.mdc-data-table__row {
      transition: all 0.15s ease-in-out;
      &.highlight {
        background-color: $selectedColor !important;
      }
      &:hover {
        background-color: $hoverColor !important;
      }
      &.disabled {
        background-color: $disable !important;
      }
    }
  }
}

::ng-deep .mat-mdc-form-field-error {
  color: #c00000 !important;
}

::ng-deep .mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

::ng-deep .mat-sort-header-arrow {
  display: none !important;
  margin-top: 3px !important;
}

::ng-deep .mat-sort-header-sorted .mat-sort-header-arrow {
  display: inline-block !important;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Material Icons';
}

::ng-deep .mat-mdc-checkbox .mdc-checkbox__background {
  width: 15px !important;
  height: 15px !important;
}

::ng-deep .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 15px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size, 40px) - 15px) / 2) !important;
}

::ng-deep .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
  border-color: #343dd6 !important;
}

::ng-deep .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab__text-label {
  color: #343dd6 !important;
}

::ng-deep .mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
  border-color: #343dd6 !important;
}
