@tailwind base;
@tailwind components;
@tailwind utilities;

$textColor: #515151;

:root {
  --mat-paginator-container-text-color: $textColor;
  --mat-toolbar-container-text-color: $textColor;
  --mat-tree-node-text-color: : $textColor;
  --mat-sidenav-container-text-color: : $textColor;
  --mat-sidenav-content-text-color: : $textColor;
  --mat-stepper-header-selected-state-label-text-color: : $textColor;
  --mat-expansion-container-text-color: : $textColor;
  --mat-expansion-header-text-color: : $textColor;
  --mat-bottom-sheet-container-text-color: : $textColor;
  --mat-standard-button-toggle-text-color: : $textColor;
  --mat-standard-button-toggle-selected-state-text-color: : $textColor;
  --mat-standard-button-toggle-disabled-selected-state-text-color: : $textColor;
  --mat-datepicker-calendar-date-text-color: : $textColor;
  --mat-datepicker-range-input-separator-color: : $textColor;
  --mat-datepicker-calendar-container-text-color: : $textColor;
  --mat-table-header-headline-color: : $textColor;
  --mat-table-row-item-label-text-color: : $textColor;
  --mat-menu-item-label-text-color: : $textColor;
  --mat-menu-item-icon-color: : $textColor;
  --mdc-list-list-item-label-text-color: : $textColor;
  --mdc-list-list-item-hover-label-text-color: : $textColor;
  --mdc-list-list-item-focus-label-text-color: : $textColor;
  --mdc-dialog-subhead-color: : $textColor;
  --mat-select-enabled-trigger-text-color: : $textColor;
  --mat-select-focused-arrow-color: : $textColor;
  --mdc-filled-text-field-focus-label-text-color: : $textColor;
  --mdc-outlined-text-field-focus-label-text-color: : $textColor;
  --mdc-outlined-text-field-hover-outline-color: : $textColor;
  --mat-form-field-focus-select-arrow-color: : $textColor;
  --mat-form-field-state-layer-color: : $textColor;
  --mat-app-text-color: : $textColor;
  --mat-optgroup-label-text-color: : $textColor;
  --mat-option-label-text-color: : $textColor;
  --mdc-checkbox-state-layer-size: 35px;
}

html,
body {
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  color: $textColor;
  background-color: #fff;
  font-size: 12px;
}
body {
  margin: 0;
}
.height-36 {
  height: 36px !important;
}

.highlight {
  background: rgba(33, 150, 243, 0.15);
}

tr.mat-row {
  height: 36px !important;
}

.scrollCustom {
  position: relative;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cacaca;
    border-radius: 10px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }
}

.input-custom {
  border: 1px solid #0000003b;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  font-size: 12px;
  outline: none;

  &.error {
    border: 1px solid #c00000 !important;
  }

  .mat-datepicker-toggle {
    .mdc-icon-button {
      width: 30px;
      height: 30px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.border-err {
  input {
    border: 1px solid #c00000 !important;
  }
}

input.border-err,
.border-err mat-select .mat-mdc-select-trigger {
  border: 1px solid #c00000 !important;
  outline-color: transparent !important;
}

.text-err {
  color: #c00000;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.el-close-dialog {
  position: fixed !important;
  right: 30px !important;
  top: 20px !important;
  background: #515151 !important;
  color: #808080 !important;
  font-size: 22px !important;
  opacity: 0;
  animation: fadeIn 0.1s ease forwards;
  animation-delay: 0.2s; 
}

table:not(.mat-calendar-table) thead tr,
.mat-mdc-table thead tr {
  height: 36px !important;
  th {
    position: sticky;
    top: 0;
    z-index: 4;
    border: none;
    background: #e2e2e2 !important;
    font-weight: bold !important;
    color: #515151 !important;
    font-size: 12px !important;

    &:not(:last-child)::after {
      position: absolute;
      content: '';
      top: 8px;
      height: calc(100% - 16px);
      right: 0;
      width: 2px;
      background: #ffffff;
    }
  }
}

.mat-sort-header-container {
  justify-content: center;
}

.dialogBgTransparent {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    --mdc-dialog-container-color: transparent;
    --mdc-dialog-container-elevation: 0 0 0 0 transparent;
  }
}

table {
  thead {
    tr {
      th[role='columnheader'] {
        &.mat-sort-header {
          div.mat-sort-header-arrow {
            .mat-sort-header-stem {
              display: none;
            }
            .mat-sort-header-indicator {
              transform: unset !important;
              div {
                --indicator-color: transparent;
                position: absolute;
                left: 0;
                transform: unset !important;
                color: var(--indicator-color);
              }
            }
          }
          &[aria-sort='ascending'] {
            .mat-sort-header-indicator {
              div {
                --indicator-color: rgba(0, 0, 0, 25%) !important;
                height: 2px;
              }
              :nth-child(1) {
                width: 100%;
                top: 0;
              }
              :nth-child(2) {
                width: 66.66%;
                top: 4px;
              }
              :nth-child(3) {
                width: 33.33%;
                top: 8px;
              }
            }
          }
          &[aria-sort='descending'] {
            .mat-sort-header-indicator {
              div {
                --indicator-color: rgba(0, 0, 0, 25%) !important;
              }
              :nth-child(1) {
                width: 33.33%;
                top: 0;
              }
              :nth-child(2) {
                width: 66.66%;
                top: 4px;
              }
              :nth-child(3) {
                width: 100%;
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

table
  thead
  tr
  th[role='columnheader'].mat-sort-header[aria-sort='descending']
  .mat-sort-header-indicator
  :nth-child(1) {
  width: 100% !important;
}
table
  thead
  tr
  th[role='columnheader'].mat-sort-header[aria-sort='descending']
  .mat-sort-header-indicator
  :nth-child(3) {
  width: 33.33% !important;
}
table thead tr th[role='columnheader'].mat-sort-header[aria-sort='ascending'] .mat-sort-header-indicator :nth-child(1) {
  width: 33.33% !important;
}
table thead tr th[role='columnheader'].mat-sort-header[aria-sort='ascending'] .mat-sort-header-indicator :nth-child(3) {
  width: 100% !important;
}

.custom-tooltip .mdc-tooltip__surface {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 12px;
  font-weight: 400;
  max-width: unset !important;
  background: white !important;
  color: #515151 !important;
  border: 1px solid #dcdce2 !important;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #343dd6;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #343dd6;
}

.mat-drawer-container,
.mat-mdc-select-value-text {
  color: #515151;
}

.icon-back {
  width: 15px !important;
  height: 15px !important;
  font-size: 15px !important;
}

.bannber-notify {
  position: absolute;
  top: 60px;
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  padding: 4px 16px;
  min-height: 32px;
}

.text-over-line {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-shadow: none;
  word-break: break-all;
  max-width: fit-content;
}

.hintElement {
  visibility: visible;
  align-content: center;
  background: #ffffff;
  border: 1px solid #dcdce2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  padding: 10px;
  position: absolute;
  margin-top: 5px;
  width: max-content;
  max-width: 300px;
  z-index: -1;
}

.line-over {
  &:hover {
    .hintElement {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }
}

// page
.mat-mdc-option {
  padding: 0 1rem !important;
}

.mat-mdc-paginator-container {
  .mat-mdc-paginator-page-size {
    margin-right: 0;
  }
  .mat-mdc-paginator-page-size-select {
    margin: 0 !important;
    .mat-mdc-form-field-infix {
      min-height: 30px;
      padding: 0 !important;
    }
  }
}
.mat-mdc-paginator-range-label {
  margin: 0 20px 0 20px !important;
}

.img__block,
.video-small {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  position: relative;
  align-items: center;
  width: 30px;
  &:before {
    border-radius: 8px;
    display: block;
    padding-bottom: 100%;
    content: '';
  }
}

.video-small {
  &:after {
    position: absolute;
    content: '';
    background: url(~src/assets/images/icon-play-youtube.png);
    background-color: red;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 11px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.video-medium {
  &:after {
    position: absolute;
    content: '';
    background: url(~src/assets/images/icon-play-youtube.png);
    background-color: red;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 28px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.itemRemove {
  margin-top: -10px;
}

.display-none {
  display: none !important;
}

.mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #a1a0a0 !important;
}

.mat-mdc-slider .mdc-slider__track--active_fill,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #343dd6;
}

.mat-mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: #a1a0a0 !important;
  border-color: #a1a0a0 !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  background-color: #e1e1e1 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #343dd6 !important;
  background-color: #343dd6 !important;
}


#notification-banner {
  position: relative;
  top: -120px;
  width: 100%;
  .notification {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 36px;
    background-color: #e2e2e2;
    z-index: -1;
    padding: 8px 16px;
    transition: all 0.3s linear;
    &.open {
      top: 110px;
      z-index: 999;
    }
  }
}
.mat-mdc-table .mdc-data-table__row {
  height: 36px;
}

.tooltip-container {
  position: absolute;
  letter-spacing: 1px;
  z-index: 1000;
  white-space: nowrap;
  transform: translateX(-50%);
  pointer-events: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 12px;
  font-weight: 400;
  max-width: unset !important;
  background: white !important;
  color: #515151 !important;
  border: 1px solid #dcdce2 !important;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
